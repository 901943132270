jQuery(document).ready(function($) {
	/* Mobile Menu */
	const mobileNavigationMenuSelector = ".mobile-navigation-menu"
	const menu = new Mmenu( mobileNavigationMenuSelector, {
		extensions: [
			"position-top"
		],
		navbar: false,
		screenReader: {
			aria: true,
			text: true,
		},
		slidingSubmenus: false
	});

	$( ".mobile-menu-button" ).click( () => {
		if ( $( mobileNavigationMenuSelector ).hasClass( "mm-menu_opened" ) ) {
			menu.API.close();
		}
		else {
			menu.API.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".uui-navbar02_dropdown-toggle-2").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".uui-navbar02_dropdown-toggle-2").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	$(".accesible-navigation-menu .nav-link").each(function() {
		if ($(this).hasClass("no-link")) {
			$(this).removeAttr("href");
		}
	});


	/* Popup Alert */
	$( ".close-popup-alert" ).click(function() {
		$(".popup-alert").hide();
		$("#page").removeClass("show-alert");
		setCookie("popupAlert", "isShown");
	});

	function setCookie(cname, cvalue) {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}

	if ( document.cookie.indexOf("popupAlert=") == -1 ) {
		$(".popup-alert").show();
		$("#page").addClass("show-alert");
	}

	/* webflow */
	try {
		Typekit.load();
	}
	catch (e) {}

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});
});

function addStickyHeader() {
	if ($(window).scrollTop() > 1) {
		$('.mega-menu').addClass('sticky');
	}
	else {
		$('.mega-menu').removeClass('sticky');
	}
}
